import { graphql, navigate } from "gatsby";
import React, { useState } from "react";
import { useTheme } from "styled-components";

import { Checkbox } from "@source-web/checkbox";
import { Heading } from "@source-web/heading";
import { IconPopOut as IconPopOutDark } from "@source-web/source-mid-render-dark-icons";
import { IconPopOut as IconPopOutLight } from "@source-web/source-mid-render-light-icons";
import { Tab, Tabs } from "@source-web/tabs";

import { ThemedSection } from "../../components";
import { ContentfulRichText } from "../../components/ContentfulRichText";
import PageHeading from "../../components/PageHeading";
import PartnerModal from "../../components/PartnerModal";
import { PrimaryButton } from "../../components/PrimaryButton";
import { isJourneyPopUp, isServer, journeyHandleClick } from "../../lib/";
import { useCheckBoxForModal } from "../servicesPage/hooks/useCheckBoxForModal";
import { Plans } from "./components";
import {
  PartnerBody,
  PartnerContent,
  PartnerFooter,
  PartnerHeader,
  PartnerModalContent,
  ProfileNavigationalSectionContainer
} from "./styles/profiles.styles";
import { ProfilePageType } from "./types";

export const query = graphql`
  query GetProfilePageData($locale: String) {
    contentfulPageHeading(
      pageName: { eq: "profile" }
      node_locale: { eq: $locale }
    ) {
      heading
      headingBackgroundImageDark {
        gatsbyImageData(height: 450, quality: 95)
      }
      headingBackgroundImageLight {
        gatsbyImageData(height: 450, quality: 95)
      }
      headingBackgroundMobileDark {
        gatsbyImageData(height: 400, quality: 95)
      }
      headingBackgroundMobileLight {
        gatsbyImageData(height: 400, quality: 95)
      }
      headingSubtext
    }

    contentfulPartnerSignInModal(node_locale: { eq: $locale }) {
      signInHeading
      redirectMessage
      redirectInfo
      redirectEmail
      redirectContact
      journeyPopUpHeading
      journeyPopUpCTA
      journeyPopUpCheckbox
      journeyPopUpBody {
        raw
      }
      journeyPopUpContactInfo {
        raw
      }
    }

    contentfulProfilePagePlans(node_locale: { eq: $locale }) {
      statuses {
        cancelled
        active
        suspended
      }
      tabName
      marketPlaceButton
      manualRenewalsDescription
      manualRenewalsTitle
      cybershopButton
      datePlaceholder
      emptyAutoRenew
      emptyManualSubscriptions
      expirationPlaceholder
      autoRenewalText
      autoRenewalMessage
      autoRenewalLink
      productName
      autoRenewalLinkText
      autoRenewalsDescriptionRich {
        raw
      }
      autoRenewalsTitle
      heading
    }
    contentfulPackageTile(
      node_locale: { eq: $locale }
      packageTile: { eq: "plans" }
    ) {
      packageTile
      widgetInfo
      buttonName
      header
      link
    }
    contentfulProfilePageOrderHistory(node_locale: { eq: $locale }) {
      id
      heading
      filterName
      tableHeadings
      linkName
      emptyOrdersMessage
      filterOptions {
        text
        value
        disabled
      }
    }
    allContentfulExplainerTile(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          summaryText {
            raw
          }
          iconAltText
          link
          mainIcon {
            file {
              url
            }
          }
          mainIconLight {
            file {
              url
            }
          }
          headingText
          isSelfServe
          productId
          childrenContentfulExplainerTileListDataJsonNode {
            text
            icon
          }
        }
      }
    }
  }
`;

function ProfilePage({
  data,
  pageContext: { formattedLocale, selectedTab }
}: ProfilePageType) {
  const {
    allContentfulExplainerTile,
    contentfulPageHeading,
    contentfulProfilePageOrderHistory,
    contentfulProfilePagePlans,
    contentfulPartnerSignInModal,
    contentfulPackageTile
  } = data;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useCheckBoxForModal(
    "productCheckBoxState",
    false
  );

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const theme = useTheme();
  const isDark = theme.name !== "WS10";
  const tabName = contentfulProfilePagePlans.tabName;

  const handleTabPackageWidget = (tabName: string) => {
    navigate(`/${formattedLocale}/profile/${tabName}`);
  };

  const tabs = selectedTab || tabName.toLowerCase().replace(" ", "");

  function formatContent(template: string, replacement: string) {
    return template.replace(/\{productName\}/g, replacement);
  }

  const productName = data.contentfulProfilePagePlans.productName;
  const formattedPopUpHeading = formatContent(
    data.contentfulPartnerSignInModal.journeyPopUpHeading,
    productName
  );
  const formattedPopUpBody = formatContent(
    data.contentfulPartnerSignInModal.journeyPopUpBody.raw,
    productName
  );
  const formattedPopUpContact = formatContent(
    data.contentfulPartnerSignInModal.journeyPopUpContactInfo.raw,
    productName
  );

  const formattedURL = data.contentfulProfilePagePlans.autoRenewalLink;
  return (
    <>
      <PageHeading
        headingText={contentfulPageHeading.heading}
        headingSubtext={contentfulPageHeading.headingSubtext}
        backgroundImgDark={contentfulPageHeading.headingBackgroundImageDark}
        formattedLocale={formattedLocale}
        havePackageWidget
        packageTileData={contentfulPackageTile}
        handleClick={(e) => {
          journeyHandleClick(
            e,
            formattedLocale,
            isChecked,
            handleOpenModal,
            handleCloseModal,
            formattedURL
          );
        }}
      />
      <PartnerModal
        isOpen={isModalOpen}
        isCloseable={true}
        onCloseCb={handleCloseModal}
      >
        <>
          <PartnerModalContent>
            <PartnerHeader>
              {isDark ? (
                <IconPopOutDark size={2} />
              ) : (
                <IconPopOutLight size={2} />
              )}

              <Heading
                text={formattedPopUpHeading}
                size={2}
                justify="center"
                level={1}
              />
            </PartnerHeader>
            {/* CYB-18487 - Removed copy from Italy market */}
            {formattedLocale !== "it" ? (
              <>
                <PartnerBody>
                  <ContentfulRichText text={formattedPopUpBody} />
                </PartnerBody>
                <PartnerContent>
                  <ContentfulRichText text={formattedPopUpContact} />
                </PartnerContent>
              </>
            ) : null}
          </PartnerModalContent>
          {formattedLocale !== "it" ? (
            <>
              <PartnerBody>
                <Checkbox
                  value="donotseepopop"
                  id="donotseepopop"
                  name="donotseepopop"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                >
                  {data.contentfulPartnerSignInModal.journeyPopUpCheckbox}
                </Checkbox>
              </PartnerBody>
              <PartnerFooter>
                <PrimaryButton
                  text={data.contentfulPartnerSignInModal.journeyPopUpCTA || ""}
                  href={data.contentfulPackageTile.link}
                  appearance={"primary"}
                  inverse={isDark}
                  onClick={(e) => {
                    isJourneyPopUp(
                      e,
                      isServer,
                      data.contentfulPackageTile.link
                    );
                    handleCloseModal();
                  }}
                />
              </PartnerFooter>
            </>
          ) : null}
        </>
      </PartnerModal>

      <ThemedSection appearance="primary">
        <ProfileNavigationalSectionContainer>
          {/* eslint-disable-next-line */}
          <Tabs
            externallySetTabId={tabs}
            onTabClick={(tabName) => handleTabPackageWidget(tabName)}
            {...(theme.name === "WS10Dark" && {
              appearance: "secondary",
              inverse: true
            })}
          >
            {/* @todo:  TIL does not send us this information 
             Wrapping the only Child inside an Array as Tabs needs Multiple Items as children*/}

            {/* <Tab text="Account" key="account" id="account">
              <Account
              />
            </Tab> */}

            {[
              <Tab
                text={tabName}
                key={tabName.toLowerCase().replace(" ", "")}
                id={tabName.toLowerCase().replace(" ", "")}
              >
                <Plans
                  formattedLocale={formattedLocale}
                  services={allContentfulExplainerTile}
                  contentfulProfilePagePlans={contentfulProfilePagePlans}
                  contentfulProfilePageOrderHistory={
                    contentfulProfilePageOrderHistory
                  }
                  contentfulPartnerSignInModal={contentfulPartnerSignInModal}
                />
              </Tab>
            ]}
          </Tabs>
        </ProfileNavigationalSectionContainer>
      </ThemedSection>
    </>
  );
}

export default ProfilePage;
